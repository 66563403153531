//========================
// define base colors
//========================
// dont set this to a color that can be interpreted as danger or warning
$primary: #1C61A6 !default;
$secondary: #3BACE2 !default;
$dark: #12110C !default;
$light: #EDF1F5 !default;

//========================
// define theme colors
//========================
$theme-1: $primary !default;
$theme-2: $secondary !default;
$theme-3: #FB8C00 !default;
$theme-4: #E34449 !default;
$theme-5: #BDE8FC !default;
$theme-colors: (
        'theme-1': $theme-1,
        'theme-2': $theme-2,
        'theme-3': $theme-3,
        'theme-4': $theme-4,
        'theme-5': $theme-5
);
$light-accent: lighten($theme-5, 10%);

//========================
// general theme options
//========================
$footer-header-color: $dark !default;
$footer-bg-color: $theme-5;
$card-link-color: $dark !default;
$link-decoration: none;
$border-radius: 25px; // radius for cards, images and buttons
$line-height-base: 1.65;

//========================
// define flavours
//========================
// Always add preserveAspectRatio="none" to the .svg
$max-flavour-height: 140px; // set max header height
$use-header-flavour: true; // flavour-header.svg
$use-footer-flavour: true; // flavour-footer.svg
$use-background-pattern: false; // flavour-pattern.png
$use-background-flavour: true;  // flavour-image.svg

//========================
//define text properties
//========================
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap" rel="stylesheet');
$font-family-base: 'Open Sans', sans-serif;
$headings-font-weight: 700;
$display-font-weight: 400;
$headings-color: #093055;
$headings-text-transform: none; // default 'none'

//========================
//define button properties
//========================
$btn-text-transform: none; // default 'none'
$btn-font-weight: 300;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-footer-style: theme-2;

//========================
//define banner spots properties
//========================
$accordion-banner-bg-color: theme-1; // Ect: theme-1, primary, white
$accordion-banner-text-color: white;


@import '../../../../src/scss/main.scss';

@import '../../../../src/scss/themes/template-barometer.scss';
